<template>
  <div class="tab-content">
    <ul class="list-unstyled intro-block">
      <li>
        <h3 class="intro-title">勝利條件</h3>
        <p>
          完成關卡裡的任務以取得好感值，好感值最高的玩家最終將贏得小桃芳心！
        </p>
      </li>
      <li>
        <h3 class="intro-title">主線關卡</h3>
        <p>
          那些年，我們在台北市的各個角落，留下了不同時期的青春回憶。進入主地圖後可任意選擇一個關卡，並由該關卡的代表角色述說他的高中回憶，破完一主線關卡才可再選擇下一個主線關卡。
        </p>
      </li>
      <li>
        <h3 class="intro-title">拍照任務</h3>
        <p>
          我們在每一處都留下了珍貴的合照留念，如今回味起來都是一幅幅美麗而難忘的畫面。在主線關卡的最後都有拍照任務，每個拍照任務都有建議的團體拍照地點，可以依循地標前往拍照，也可以直接在任意一處拍團照上傳照片。在遊戲最後，你們的青春回憶會全部被蒐集起來的！
        </p>
      </li>
      <li>
        <h3 class="intro-title">支線</h3>
        <p>
          進入每個主線關卡的區域以後，會自動解鎖該區域的支線——美食任務；完成支線任務會增加個人角色好感值，不完成也不影響主線關卡推進，可視遊戲時間自行決定是否要解支線。
        </p>
      </li>
      <li>
        <h3 class="intro-title">支線美食任務</h3>
        <p>
          吃貨小桃的回憶往事將分佈在美食任務之中，了解得越深也越有機會得到小桃芳心喔！美食任務中的謎題不需要到現場就可以解，只有最先解開謎題者會增加好感值，不進行也不會影響主線，可視時間斟酌遊玩；<br />
          為節省時間和體力，建議有興趣品嚐的美食再前往。
        </p>
      </li>
      <li>
        <h3 class="intro-title">角色技能</h3>
        <p>
          每個角色都有其能力特質，在追求小桃的過程中都會顯現出每個角色的優勢，打開「角色」頁籤查看你的專屬技能吧！
        </p>
      </li>
      <li>
        <h3 class="intro-title">好感值</h3>
        <p>
          在每一道主線關卡裡搶先答出謎題、完成任務、或在競賽中獲得第一名都會增加好感值，搶先完成支線美食任務也可增加好感值。反之，使用提示則會降低得到的好感值。好感值的高低會影響最後結局走向，請努力展現自己搏取小桃的芳心吧！
        </p>
      </li>
      <li>
        <h3 class="intro-title">同遊隊伍</h3>
        <p>
          若你們有兩組以上的隊伍同時進行遊戲的話，將在同遊隊伍列表看到其他隊伍的遊戲進度，也可以在大地圖查看其他隊伍的所在位置。
        </p>
      </li>
      <li>
        <h3 class="intro-title">遊戲時間</h3>
        <p>遊戲開始後，須在24小時內完成。遊戲中的每一個任務謎題，在越短的時間內完成，能得到越高的好感值。</p>
      </li>
      <li>
        <h3 class="intro-title">遊戲不正常運作</h3>
        <p>
          遊戲時請務必手動關閉WIFI、全程使用4G連線，以免手機連到路旁的WIFI、造成遊戲資料不同步。若遊玩過程中，不慎斷線或是螢幕上鎖導致斷線，請先強制關閉運行中的遊戲APP，再點擊手機桌面的遊戲APP圖示重啟遊戲，接著等待15秒，資料同步完成後即可正常遊戲。
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "infoTab",
  props: {
    introTab: {}
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/__var";

.main-menu-tabs li {
  flex: 1;
  cursor: hand;
}

strong {
  color: #4285f4;
}
</style>
