<template>
  <div>
    <div class="d-flex">
      <div class="position-relative">
        <div class="skill-con text-right">
          <!-- 被動 -->
        </div>
        <div class="skill-bar align-left position-relative d-flex">
          <span class="skill-des">被動技能</span>
          <span class="skill-name">{{ skillName }}</span>
        </div>
      </div>
    </div>
    <div class="py-2 skill-intro">
      {{ skillIntro }}
    </div>
  </div>
</template>

<script>
export default {
  name: "skill",
  props: {
    skill: null
  },
  data() {
    return {};
  },
  computed: {
    skillArray() {
      return this.skill.split("－");
    },
    skillName() {
      return this.skillArray?.[0];
    },
    skillIntro() {
      return this.skillArray?.[1];
    }
  },
  mounted() {}
};
</script>

<style scoped>
.skill-con {
  height: 18px;
  font-size: 12px;
  color: #224363;
}
.skill-name {
  width: 100px;
  text-align: center;
}
.skill-intro {
  font-size: 14px;
  color: #224363;
  width: 164px;
}
</style>
