<template>
  <header class="stage-header fixed-top" v-show="!fake_full_screen">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center position-relative">
        <div class="cha-circle circle mr-2 position-relative">
          <img class="cover-fit" v-bind:src="showAvatar(character.picture)" />
        </div>
        <div>
          <div class="stage-time align-left position-relative d-flex">
            <span>{{ stageLocation.time }}</span>
            <span v-if="stageLocation.location != ''" class="stage-location">{{ stageLocation.location }}</span>
          </div>
          <div>{{ character.name }}的回憶</div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <a role="button" class="mr-3" @click="backMap">
          <svg class="btn-icon-28">
            <use xlink:href="#icon-map-location" class="fill-blue active"></use>
          </svg>
        </a>
        <a
          role="button"
          v-scroll-to="{
            el: '#app',
            container: '.full-page',
            duration: 300,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }"
        >
          <!-- <svg class="btn-icon-28">
            <use xlink:href="#icon-up" class="fill-blue active"></use>
          </svg> -->
          <div class="icon-up-size">
            <img src="/img/icon-up.png" />
          </div>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import GoTop from "@inotom/vue-go-top";
import { mapGetters } from "vuex";

export default {
  name: "stageHeader",
  mixins: [Avatar],
  components: {
    // eslint-disable-next-line
      GoTop,
    // scroller
  },
  computed: {
    ...mapGetters("Video", ["fake_full_screen"])
  },
  props: {
    character: {
      default: () => {}
    },
    stageLocation: {
      default: () => {}
    }
  },
  methods: {
    backMap() {
      this.$router.replace("/homeMap");
    }
  }
};
</script>

<style scoped lang="scss">
.cha-circle {
  z-index: 2;
}

.stage-time {
  background: #939393;
  font-size: 12px;
  color: #fff;
  left: -30px;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
  span {
    padding: 2px 10px 2px 30px;
    white-space: nowrap;
    &.stage-location {
      padding: 2px 15px;
      background: #2e2e2e;
      border-radius: 20px;
    }
  }
}

.icon-up-size {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 18.6px;
  height: 18.6px;
}
</style>
