var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fake_full_screen),expression:"!fake_full_screen"}],staticClass:"stage-header fixed-top"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center position-relative"},[_c('div',{staticClass:"cha-circle circle mr-2 position-relative"},[_c('img',{staticClass:"cover-fit",attrs:{"src":_vm.showAvatar(_vm.character.picture)}})]),_c('div',[_c('div',{staticClass:"stage-time align-left position-relative d-flex"},[_c('span',[_vm._v(_vm._s(_vm.stageLocation.time))]),(_vm.stageLocation.location != '')?_c('span',{staticClass:"stage-location"},[_vm._v(_vm._s(_vm.stageLocation.location))]):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.character.name)+"的回憶")])])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('a',{staticClass:"mr-3",attrs:{"role":"button"},on:{"click":_vm.backMap}},[_c('svg',{staticClass:"btn-icon-28"},[_c('use',{staticClass:"fill-blue active",attrs:{"xlink:href":"#icon-map-location"}})])]),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: '#app',
          container: '.full-page',
          duration: 300,
          force: true,
          cancelable: true,
          x: false,
          y: true
        }),expression:"{\n          el: '#app',\n          container: '.full-page',\n          duration: 300,\n          force: true,\n          cancelable: true,\n          x: false,\n          y: true\n        }"}],attrs:{"role":"button"}},[_vm._m(0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-up-size"},[_c('img',{attrs:{"src":"/img/icon-up.png"}})])}]

export { render, staticRenderFns }