<template>
  <div class="main-favorability position-relative d-flex align-items-center">
    <div class="favorite-progress">
      <div class="favorite-progress-bar" v-bind:style="{ width: percent + '%' }"></div>
    </div>
    <div class="heart-container position-absolute" :class="{ 'd-none': !showHeart }">
      <svg class="big-heart ">
        <use xlink:href="#icon-heart" class="heart-shadow" v-bind:class="[heart]"></use>
      </svg>
      <div class="text-white position-absolute" v-show="heartTxt">LOVE</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "mainFavorability",
  props: {
    score: {
      default: 0,
      type: Number
    },
    showHeart: {
      default: true,
      type: Boolean
    },
    progress: {
      default: true,
      type: Boolean
    },
    stage_progress: {
      default: false,
      type: Boolean
    },
    default_percent: {
      default: false
    }
  },
  computed: {
    ...mapGetters("Stage", ["flag"]),
    percent() {
      if (this.default_percent) return this.default_percent;
      let percentage = this.progress ? this.score / 100 : this.score / 400;
      if (this.stage_progress) {
        percentage = (100 / this.showProgressStageFlags.length) * this.checkStageFlagCount;
      }
      return percentage > 100 ? 100 : percentage;
    },
    heartTxt() {
      return this.score >= 10000;
    },
    heart() {
      return this.score >= 10000 ? "fill-heart" : "fill-heart-not";
    },
    checkStageFlagCount() {
      return this.showProgressStageFlags.map(d => this.flag(d)).filter(d => d).length;
    }
  },
  data() {
    return {
      showProgressStageFlags: [
        "stage_1_clear",
        "stage_2_clear",
        "stage_3_clear",
        "stage_4_clear",
        "stage_5_clear",
        "stage_6_clear",
        "stage_7_clear"
      ]
    };
  }
};
</script>

<style scoped></style>
