<template>
  <div class="fullpage-paused" v-show="gamePaused">
    <fullpageInfo :hideBtn="true"></fullpageInfo>
    <div
      @click.prevent="restartGame"
      class="pause d-flex flex-column justify-content-center align-content-center text-center"
    >
      <h4 class="my-1 pause-text text-white">隊長 {{ leaderName }}</h4>
      <h4 class="my-3 pause-text text-white">隊長凍結了遊戲時間</h4>
      <div class="my-3 paused-icon d-flex flex-row justify-content-center align-content-center">
        <div class="pause-bar"></div>
        <div class="pause-bar"></div>
      </div>
      <div class="pause-text text-white py-3 my-5" id="remained-time">暫停時間剩下 {{ remainedTimeStr }}</div>
      <h4 class="pause-text text-white px-3">
        隊長再次點擊暫停鈕將結束凍結時間，繼續進行遊戲；點擊後請等待30秒讓遊戲狀態同步
      </h4>
    </div>
  </div>
</template>
<script>
import fullpageInfo from "@/views/part/fullpageInfo";
import { mapActions, mapGetters } from "vuex";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import ModalMixin from "@/Mixins/ModalMixin";

export default {
  mixins: [TeamFromVuex, ModalMixin],
  data() {
    return {
      diffTime: 0,
      maximunPausedInMillSec: 3 * 60 * 60 * 1000,
      countDownInterval: null
    };
  },
  components: {
    fullpageInfo
  },
  computed: {
    ...mapGetters("Team", ["leader", "isLeader", "paused_count", "paused_at"]),
    ...mapGetters("Game", ["gamePaused"]),
    leaderName() {
      return this.leader?.user?.nickname;
    },
    remainedTimeStr() {
      let now = new Date();
      if (typeof this.paused_at == "undefined") return;

      let paused_at = new Date(this.paused_at);
      //eslint-disable-next-line
      this.diffTime = this.maximunPausedInMillSec - Math.abs(now - paused_at);

      if (this.diffTime >= 0) {
        let hour = Math.floor(this.diffTime / (60 * 60 * 1000));
        let remaind_sec = this.diffTime % (60 * 60 * 1000);
        let minute = Math.floor(remaind_sec / (60 * 1000));
        let sec = Math.floor((remaind_sec % (60 * 1000)) / 1000);
        return `${hour}h ${minute}m ${sec}s`;
      } else {
        return `0h 0m 0s`;
      }
    }
  },
  mounted() {
    this.resetInterVal();
    if (this.countDownInterval == null && this.gamePaused && this.diffTime > 1000) {
      this.countDownInterval = setInterval(() => {
        this.countDown();
      }, 1000);
    }
  },
  methods: {
    ...mapActions("Game", ["toggleGamePause", "gameLogCreate"]),
    ...mapActions("User", ["setFd"]),
    restartGame() {
      if (!this.allMemberOnline) {
        this.showModal("請確認所有人在線上 才能開始遊戲歐");
        return;
      }
      if (!this.isLeader) return;
      this.resetInterVal();
      this.toggleGamePause(false);
    },
    countDown() {
      this.diffTime -= 1000;
    },
    resetInterVal() {
      clearInterval(this.countDownInterval);
      this.countDownInterval = null;
    }
  },
  watch: {
    diffTime: {
      handler: function(newVal) {
        if (this.countDownInterval == null && this.gamePaused) {
          this.countDownInterval = setInterval(() => {
            this.countDown();
          }, 1000);
        } else if (this.gamePaused && newVal <= 1000) {
          this.restartGame();
        }
      }
    }
  },
  destroyed() {
    clearInterval(this.countDownInterval);
    this.countDownInterval = null;
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/__var";
.fullpage-paused {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(70, 70, 70, 0.8);
  z-index: 10001;
}
.pause {
  position: absolute;
  top: 25vh;
  width: 100vw;
  height: 50vh;

  .pause-bar {
    width: 20px;
    height: 70px;
    margin: 0 10px;
    background: #fff;
  }
}

#remained-time {
  background-color: $main-color;
}
</style>
