<template lang="pug">
.div
  .tab-switch.position-relative
    div(class="position-absolute tab-words d-flex align-items-center text-center h-100")
      a(role="button" @click="switchTeamTab(0)") 本隊隊伍
      a(role="button" @click="switchTeamTab(1)") 同遊隊伍
    div.tab-btn.position-absolute(:style="{ left: teamCon * 50 + '%' }")
  .tab-container
    div(v-show="teamCon == 0")
      .position-absolute.team-rank-list.text-right
        h3.min-title 隊伍排名
      .position-relative.team-score2
        div.team-title
          h3.intro-title.team-name {{team_name}}
          h3.sub-title.mt-2 隊伍好感值:
            span.intro-title.team-love.ml-2  {{team_score}}
        .cha-circle.circle.team-rank-list.position-absolute
          | {{ team.rank || 1 }}
        mainFavorability(:score="team_score || 0" :progress="false")
      .position-relative.team-score2
        h3.sub-title 遊戲進度
        mainFavorability(:stage_progress="true" :showHeart="false")
      .team-score-card
        ul.list-unstyled.m-0.mt-4
          teamMemberScore(v-for="(member, index) in score_list"
            :member="member"
            :rank="index"
            :key="member.id")
    div(v-show="teamCon == 1")
      .team-block(v-for="(team, team_index) in score_of_teams" :key="team_index")
        .position-absolute.team-rank-list.text-right
          h3.min-title 隊伍排名
        .position-relative.team-score.team-score2
          div.team-title
            h3.intro-title.team-name.dropdown-toggle.team-name(data-toggle="collapse" :href="`#member_score_${team_index}`" role="button" aria-expanded="false" :aria-controls="`#member_score_${team_index}`")
              | {{ limitTeamName(team) }}
            h3.sub-title.mt-2 隊伍好感值:
              span.intro-title.team-love.ml-2  {{score_of_teams[team_index].total_score}}
            .cha-circle.circle.team-rank-list.position-absolute
              | {{ parseInt(team_index) + 1 }}
          mainFavorability(:score="score_of_teams[team_index].total_score" :progress="false")
        .position-relative.team-score.team-score2
          h3.sub-title 遊戲進度
          mainFavorability(:default_percent="team.stage_clear_count" :showHeart="false")
        .team-score-card.collapse.multi-collapse(:id="`member_score_${team_index}`")
          ul.list-unstyled
            teamMemberScore(v-for="(member, index) in team.members"
              :member="member"
              :rank="index"
              :key="member.id"
              :roleName="getRoleName(team.id, member.user_id)")
    .team-score-card 
      div.team-id 隊伍ID : {{this.team.code}}
</template>
<script>
import mainFavorability from "@/views/part/mainFavorability";
import teamMemberScore from "@/views/part/teamMemberScore";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    refreshData: {
      default: false,
      type: Boolean
    }
  },
  components: {
    teamMemberScore,
    mainFavorability
  },
  computed: {
    ...mapGetters("Game", ["game", "score_of_teams", "teams_user_selected"]),
    ...mapGetters("Team", ["character_map"]),
    ...mapGetters("Score", ["score_map"]),
    ...mapGetters("Stage", ["stages", "flags"]),
    ...mapGetters("Character", ["roles"]),
    clearFlags() {
      let clearFlags = Object.keys(this.flags).filter(flag => {
        return flag.toLowerCase().includes("clear");
      });

      return clearFlags;
    },
    team_progress() {
      let finished = this.clearFlags.filter(flag => {
        return this.flags[flag] === true;
      }).length;
      return Math.floor((finished / this.clearFlags.length) * 100);
    },
    team_name() {
      if (this.team.title === "隊伍名稱") {
        let leader = this.members.find(member => member.user_id === this.team.payload?.leader);
        let leader_name = leader.user.nickname;
        return leader_name + "  懶得想名字";
      }
      return this.team.title;
    },
    team_score() {
      return Object.values(this.score_map).reduce((a, b) => a + b, 0);
    },
    score_list() {
      return this.members
        .map(member => {
          const character = this.character_map.find(d => d.user_id == member.user_id).character;
          return {
            id: member.user_id,
            name: character.name,
            nickname: member?.user?.nickname,
            picture: member?.user?.avatar,
            score: this.score_map?.[member.user_id] || 0
          };
        })
        .sort((a, b) => b.score - a.score);
    }
  },
  data() {
    return {
      teamCon: 0
    };
  },
  methods: {
    ...mapActions("User", ["flagSyncTimer"]),
    ...mapActions("Game", ["scoreOfTeams"]),
    async switchTeamTab(num) {
      this.teamCon = num;
      if (num == 1) {
        await this.scoreOfTeams(this.game?.code);
      }
    },
    limitTeamName(team) {
      if (team?.name === "隊伍名稱") {
        return team?.members?.[0]?.nickname + "懶得想名字";
      } else if (team.name.length > 5) {
        return `${team.name.substring(0, 5)}...`;
      } else {
        return team.name;
      }
    },
    game_progress(stage_clear_count) {
      return this.clearFlags.length > 0 ? Math.round((stage_clear_count / this.clearFlags.length) * 100) : 0;
    },
    getRoleName(team_id, user_id) {
      let charactor_id = this.teams_user_selected?.[team_id]?.[user_id];
      return this.roles[charactor_id]?.name;
    }
  },
  watch: {
    refreshData: {
      handler: async function(newVal) {
        if (newVal) {
          await this.scoreOfTeams(this.game?.code);
        }
      }
    }
  },
  mixins: [TeamFromVuex]
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/__var";
.team-block {
  &:nth-child(2n + 1) {
    border-bottom: 1px solid $light-color;
  }
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.team-score {
  padding: 40px 0 0;
}
.team-title {
  margin-bottom: 12px;
}
.team-score2 {
  padding: 10px 0 0;
  .team-name {
    width: calc(100% - 110px);
    white-space: normal;
  }
}
.team-love {
  color: #fd5f7e;
  line-height: 28px;
}
.sub-title {
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
}

.team-rank-list {
  top: 0;
  right: 20px;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-rank {
  width: calc(100% + 20px);
  background: $info-color;
  color: #fff;
  margin: 0 -10px;
  border-radius: 4px;
  padding: 8px 20px;
  @include default-shadow;
}

.text-right {
  top: 70px;
  right: 62px;
}

.min-title {
  font-size: 12px;
  line-height: 28px;
}
.team-id {
  padding: 0 30px;
  color: #666;
}
</style>
