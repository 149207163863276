<template>
  <div class="position-relative" v-show="!fake_full_screen">
    <gamePause></gamePause>
    <div class="half-circle position-fixed" :class="{ active: showContent, 'hide-menu': collapse }">
      <div @click="showContent = !showContent" class="menu-circle d-flex justify-content-center">
        <div class="align-items-center menu-arrow">^</div>
      </div>
    </div>
    <div class="main-menu position-fixed" v-bind:class="{ active: showContent, 'hide-menu': collapse }">
      <ul class="list-unstyled d-flex justify-content-between text-center main-menu-tabs">
        <li @click="showUserTab" class="cursor-default">
          <svg class="btn-icon-24">
            <use xlink:href="#icon-user" class="fill-blue" v-bind:class="{ active: userTab }"></use>
          </svg>
          <div class="small-font">角色</div>
        </li>
        <li @click="showTeamTab" class="cursor-default">
          <svg class="btn-icon-24">
            <use xlink:href="#icon-team" class="fill-blue" v-bind:class="{ active: teamTab }"></use>
          </svg>
          <div class="small-font">隊伍</div>
        </li>
        <li @click="showInfoTab" class="cursor-default">
          <svg class="btn-icon-24">
            <use xlink:href="#icon-intro" class="fill-blue" v-bind:class="{ active: introTab }"></use>
          </svg>
          <div class="small-font">說明</div>
        </li>
        <li @click="showSettingTab" class="cursor-default">
          <svg class="btn-icon-24">
            <use xlink:href="#icon-more" class="fill-blue" v-bind:class="{ active: settingTab }"></use>
          </svg>
          <div class="small-font" @click="toggleMenu">設定</div>
        </li>
      </ul>
      <transition name="fade">
        <div
          v-show="userTab"
          class="tab-content character-tab"
          :style="{ 'background-image': 'url(/img/' + character.figure + ')' }"
        >
          <div class="py-3 d-flex align-items-center" v-if="user">
            <div class="cha-circle circle mr-3">
              <img class="cover-fit" v-bind:src="showAvatar(user.avatar)" />
            </div>
            <div class="character-text">
              <!-- <div class="text-truncate">{{ user.email }}</div> -->
              <div class="text-truncate">{{ user.nickname }}</div>
            </div>
          </div>
          <h3 class="intro-title">好感度</h3>
          <mainFavorability :score="getScore()"></mainFavorability>
          <div class="cha-block cha1 d-flex align-items-center">
            <h3 class="intro-title">{{ character && character.name }}</h3>
          </div>
          <div class="position-relative character-skill">
            <skill :skill="character.ability"></skill>
          </div>
          <div class="position-absolute character-intro" v-html="character && character.intro"></div>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="teamTab" class="tab-content">
          <teamScoreContent :refreshData="showContent"></teamScoreContent>
        </div>
      </transition>
      <transition name="fade">
        <infoTab v-if="introTab" />
      </transition>
      <transition name="fade">
        <div v-if="settingTab" class="tab-content">
          <div class="tab-switch position-relative">
            <div class="position-absolute tab-words d-flex align-items-center text-center h-100">
              <a role="button" @click="switchSettingTab(0)">排行榜</a>
              <a role="button" @click="switchSettingTab(1)">基本設定</a>
            </div>
            <div class="tab-btn position-absolute" :style="{ left: settingCon * 50 + '%' }"></div>
          </div>
          <div class="tab-container">
            <div class="" v-show="settingCon == 0">
              <h4 class="font-28 text-center">功能即將開放</h4>
              <!-- <h4 class="font-14">伺服器前十名</h4>
              <ul class="list-unstyled rank-list font-14">
                <serverTopItem v-for="(team, index) in serverRank" :team="team" :key="index"></serverTopItem>
              </ul>
              <h4 class="font-14">百分比</h4>
              <div class="position-relative rank-percent-wrap">
                <div
                  class="team-rank position-absolute d-flex font-14 align-items-center"
                  v-bind:style="{ top: rankPosition + 'px' }"
                >
                  <div class="cha-circle circle mr-3">
                    <img class="cover-fit" v-bind:src="showAvatar(team.picture)" />
                  </div>
                  <div class="mr-3">{{ team_percent || 0 }}%</div>
                  <div class="team-name text-truncate mr-3">
                    {{ team.name }}
                  </div>
                  <div>
                    {{ team.score || 0 }}
                  </div>
                </div>
                <ul class="list-unstyled rank-list font-14 rank-percent">
                  <rankPercent v-for="team in rankPercent" :team="team" :key="team.id"></rankPercent>
                </ul>
              </div> -->
            </div>
            <div class="" v-show="settingCon == 1">
              <ul class="list-unstyled intro-block">
                <li>
                  <h3 class="intro-title">遊戲設定</h3>
                  <ul class="list-unstyled control-list">
                    <!-- <li class="d-flex align-items-center">
                      <a class="d-flex justify-content-between">
                        <div>更換隊長</div>
                        <svg class="btn-icon-28" @click="$router.replace('teamReForm')">
                          <use xlink:href="#icon-user-setting" class="fill-blue active"></use>
                        </svg>
                      </a>
                    </li> -->
                    <li>
                      <a
                        @click="pauseGame"
                        v-bind:class="{ disabled: !isLeader || paused_count > 0 }"
                        :disabled="!isLeader || paused_count > 0"
                      >
                        用餐凍結時間（隊長功能）
                        <span v-if="paused_count > 0">已暫停過</span>
                      </a>
                    </li>
                    <li v-if="isDebug">
                      <a class="" @click="syncFlag">同步隊伍遊戲進度</a>
                    </li>
                    <li v-if="isDebug">
                      <a class="" @click="everyGoHome">所有人回大地圖</a>
                    </li>
                    <li v-show="flag('can_logout')">
                      <a class="" @click="logout">logout</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 class="intro-title">其他功能</h3>
                  <ul class="list-unstyled control-list">
                    <li>
                      <a href="https://vimeo.com/431824954/522c64d067" target="_blank">開頭影片</a>
                    </li>
                    <li>
                      <a href="https://www.badideasstudio.com/2020/08/theyears-credits/" target="_blank"
                        >製作人員名單</a
                      >
                    </li>
                    <li class="d-flex align-items-center">
                      <a class="d-flex justify-content-between" data-toggle="collapse" href="#noPower" role="button">
                        <div>手機快沒電請點我</div>
                        <img class="icon" src="/img/icons/chargeSPOT-icon.svg" alt="" />
                      </a>
                    </li>
                    <li class="my-2">
                      <div class="collapse multi-collapse" id="noPower">
                        <div class="card card-body">
                          <div>
                            <p>
                              手機要沒電了嗎？早就幫你想好啦！壞主ㄧ意與ChargeSPOT合作，無論你在哪個關卡都可以輕鬆找到站點借電喔！也不用擔心忘了帶充電線，第一關借、最後一關再還也都可以，不用走回原點喔！
                            </p>
                            <p>
                              新用戶下載APP輸入<strong><b>「壞主意的那些年」</b></strong
                              >可立即獲得3張60分鐘借電優惠券！
                            </p>
                            <p>
                              無論新舊用戶都可輸入<strong><b>「一起的戀愛滋味」</b></strong
                              >，可立即獲得1張30分鐘借電優惠券！
                            </p>
                          </div>
                          <a href="https://download.chargespot.tw/" target="_blank">👉 下載 ChargeSPOT </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a data-toggle="collapse" href="#moreQuestion" role="button">常見問題</a>
                    </li>
                    <li>
                      <div
                        class="collapse multi-collapse card card-body"
                        id="moreQuestion"
                        v-for="(qaType, typeIndex) in qa"
                        :key="typeIndex"
                      >
                        <h4 v-if="qaType.type != ''">{{ qaType.type }}</h4>
                        <ul class="qa-list list-unstyled">
                          <li v-for="(content, index) in qaType.content" class="mb-2" :key="index">
                            <a
                              class="collapsed-item row mx-1"
                              role="button"
                              data-toggle="collapse"
                              :href="'#question' + index"
                            >
                              <b>Q{{ index + 1 }}: {{ content.q }}</b>
                            </a>
                            <div class="py-2 collapse multi-collapse " :id="'question' + index">{{ content.a }}</div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="d-flex align-items-center">
                      <a class="d-flex justify-content-between" @click="customService">
                        <div>聯絡客服（10:00-20:00）</div>
                        <img class="icon" src="/img/icons/line.svg" alt="" />
                      </a>
                    </li>
                    <li class="d-flex align-items-center">
                      <img style="width: 30%; margin: 0 auto;" src="/img/logo.jpg" @click="unlockDebug" />
                    </li>
                  </ul>
                </li>

                <li v-if="isDebug">
                  <h3>開發者功能</h3>
                  <h4>設置Flag</h4>
                  <div v-for="flagName in debugFlags" :key="flagName">
                    <input
                      type="checkbox"
                      :checked="getFlag(flagName)"
                      @input="_setFlag(flagName, $event.target.checked)"
                      name="checkTest"
                      :id="'check' + flagName"
                    /><label :for="'check' + flagName"> {{ flagName }}</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";

import mainFavorability from "@/views/part/mainFavorability";
import teamScoreContent from "@/views/team/teamScore";
import gamePause from "@/views/team/GamePause";
import UserFromVuex from "@/Mixins/UserFromVuex";
import skill from "@/views/part/skill";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import { mapActions, mapGetters } from "vuex";
import ModalMixin from "../../Mixins/ModalMixin";
import InfoTab from "@/views/part/InfoTab";
import { sendMessage } from "@/utils/WebSocket";

export default {
  name: "mainMenu",
  mixins: [Avatar, UserFromVuex, TeamFromVuex, ModalMixin],
  components: {
    InfoTab,
    mainFavorability,
    teamScoreContent,
    skill,
    gamePause
  },
  props: {
    collapse: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("Team", ["character_map", "isLeader", "paused_count"]),
    ...mapGetters("Score", ["score_map"]),
    ...mapGetters("Video", ["fake_full_screen"]),
    ...mapGetters("Stage", ["flag"]),

    team_score() {
      return Object.values(this.score_map).reduce((a, b) => a + b, 0);
    },
    team_percent() {
      let temp = this.team_score > 500 ? 500 : this.team_score;
      return temp / 5;
    },
    isDebug() {
      if (this.unlockDebugCount > 7) return true;
      /*
      if (this.flag("touchable")) return true;
      if (this.character_map[0].user_id < 10) {
        //設定10號以內的序號都是測試帳號
        return true;
      }*/
      return false;
    }
  },
  data() {
    return {
      default_user: null,
      serverRank: [
        {
          rank: 1,
          name: "hello",
          score: 11111,
          picture: ""
        },
        {
          rank: 2,
          name: "yes",
          score: 1111,
          picture: ""
        }
      ],
      rankPercent: [
        {
          percent: 100,
          score: 11111
        },
        {
          percent: 90,
          score: 11111
        },
        {
          percent: 80,
          score: 11111
        },
        {
          percent: 70,
          score: 11111
        },
        {
          percent: 60,
          score: 11111
        },
        {
          percent: 50,
          score: 11111
        },
        {
          percent: 40,
          score: 11111
        },
        {
          percent: 30,
          score: 11111
        },
        {
          percent: 20,
          score: 11111
        },
        {
          percent: 10,
          score: 11111
        },
        {
          percent: 0,
          score: 11111
        }
      ],
      showContent: false, //展開
      userTab: true,
      teamTab: false,
      introTab: false,
      settingTab: false,
      settingCon: 1,
      unlockDebugCount: 0,
      qa: [
        {
          type: "",
          content: [
            {
              q: "我有多少遊戲時間？",
              a:
                "使用遊戲序號開通後會有24小時遊戲時間，可以在這時間內悠閒的享受遊戲內容。可以悠哉地盡情回味遊戲裡每一段回憶。"
            },
            {
              q: "抵達任務地點？我現在該做什麼？",
              a:
                "當出現這按鈕時，表示你們需要到達指定地點才能繼續任務。請到地圖頁面上找尋地點標記，確定到達後，全員按下此按鈕，後續事件將自動進行"
            },
            {
              q: "我可以暫停遊戲嗎？",
              a: "遊戲內提供一次暫停的機會，隊長可以將遊戲暫停並且不影響評分。暫停時間為3小時，暫停期間可以吃飯、休息"
            },
            // {
            //   q: "可以換隊長嗎？",
            //   a:
            //     "請隊長在遊戲頁面的右下角找到『設定』按鈕，進入設定選單後點擊『更換隊長』，接著選擇新的隊長人選，最後按下確認鍵即可完成更換"
            // },
            {
              q: "關卡無法正常操作？",
              a:
                "由於遊戲過程須取得手機硬體的權限方能正常運行，在詢問授權硬體時，請務必選擇『允許』，否則會無法正常遊戲。若遊玩過程中關閉遊戲頁面，重返時不慎按到『不允許』，請關掉運行中的遊戲頁面，重啟遊戲後按下『允許』，方可正常進行遊戲。"
            },
            {
              q: "我卡關了，能幫幫我嗎？",
              a:
                "可以找找看『TIP鈴鐺』按鈕，按下會有更多線索唷！如果沒有鈴鐺的頁面，可以將頁面上的所有選項都試過一次看看"
            },
            {
              q: "我一定要上傳照片嗎？",
              a:
                "對！每一張照片都構成我們的那些年，記得用照片留下你們的回憶。遊戲結束後，我們會將你們的青春集結讓你們回顧唷！"
            },
            {
              q: "我一定要到指定地點拍照嗎？",
              a: "拍照地點是我們精心挑選過拍起來好看的場景，如果不想長途跋涉的話也可以就地拍照，發揮你們的創意囉！"
            },
            {
              q: "我一定要逛西門町嗎？",
              a:
                "建議路線上的景觀會比較符合遊戲內容。如果你們有更好的計劃也可以自由行動，只要隊伍有在走動，遊戲就會繼續進行！"
            },
            {
              q: "我不知道現在要做什麼？",
              a: "在大地圖上選擇一角色頭像傾聽他的回憶進行關卡，若已在關卡裡的話就可以看看是否有下一步指示喔！"
            },
            {
              q: "我用iPhone但地圖上一直抓不到我的GPS位置？",
              a:
                "由於蘋果的iOS調整了隱私設定，所以部分iPhone玩家可能會遭遇GPS抓不到位置的情況。這時候請點選iPhone上的〔設定〕，進入後選擇〔隱私權〕，再選擇〔定位服務〕，接著往下滑到〔Safari網站〕，最後選擇〔使用App期間〕。"
            },
            {
              q: "畫面卡住、版面錯誤、或其他遊戲故障時怎麼辦？",
              a:
                "請先強制關閉運行中的遊戲APP，再點擊手機桌面的遊戲APP圖示重啟遊戲，等待15秒讓遊戲資料同步後即可正常遊戲。"
            }
          ]
        }
      ],
      //因為flag眾多，以下挑選出在列表中可修改監控的flag
      debugFlags: [
        "stage_1_default",
        "stage_1_phase1Clear",
        "stage_1_check1stPlace",
        "stage_1_clear",
        "stage_2_default",
        "stage_2_phase1Clear",
        "stage_2_check1stPlace",
        "stage_2_clear",
        "stage_3_default",
        "stage_3_phase1Clear",
        "stage_3_check1stPlace",
        "stage_3_clear",
        "stage_4_default",
        "stage_4_phase1Clear",
        "stage_4_check1stPlace",
        "stage_4_clear",
        "stage_5_default",
        "stage_5_check1stPlace",
        "stage_5_clear",
        "stage_6_default",
        "stage_6_check1stPlace",
        "stage_6_clear",
        "stage_7_default",
        "stage_7_phase1Clear",
        "stage_7_check1stPlace",
        "stage_7_ghostFinish",
        "stage_7_clear",
        "scoreResultEntered"
      ]
    };
  },
  methods: {
    ...mapActions("User", ["flagSyncTimer"]),
    ...mapActions("Game", ["toggleGamePause", "gameLogCreate"]),
    ...mapActions("Stage", ["setFlag", "forceSyncRemoteFlags"]),
    syncFlag() {
      this.forceSyncRemoteFlags();
    },
    everyGoHome() {
      const data = {
        method: "EnterStage",
        token: this.token,
        location: {
          link: "homeMap"
        }
      };
      sendMessage(data);
    },
    toggleMenu() {
      // if (this.showContent == false) {
      //   this.showContent = true;
      //   return;
      // }
      // if (this[target] == true) {
      //   this.showContent = !this.showContent;
      // }
    },
    getScore() {
      return this.score_map[this.user.id];
    },
    showUserTab: function() {
      this.toggleMenu("userTab");
      this.userTab = true;
      this.teamTab = false;
      this.introTab = false;
      this.settingTab = false;
      this.showContent = true;
    },
    showTeamTab: function() {
      this.toggleMenu("teamTab");

      this.userTab = false;
      this.teamTab = true;
      this.introTab = false;
      this.settingTab = false;
      this.showContent = true;
    },
    showInfoTab: function() {
      this.toggleMenu("introTab");

      this.userTab = false;
      this.teamTab = false;
      this.introTab = true;
      this.settingTab = false;
      this.showContent = true;
    },
    showSettingTab: function() {
      this.toggleMenu("settingTab");
      this.userTab = false;
      this.teamTab = false;
      this.introTab = false;
      this.settingTab = true;
      this.showContent = true;
    },
    unlockDebug: function() {
      this.unlockDebugCount++;
    },
    switchSettingTab: function(num) {
      this.settingCon = num;
    },
    logout() {
      this.$router.replace("/logout");
    },
    pauseGame() {
      if (!this.isLeader || this.paused_count > 0) return;

      if (!this.flag("time_pause")) {
        this.showModal("用餐凍結時間僅能使用一次，凍結時無法繼續進行遊戲；再次點擊凍結按鈕，即可開始凍結時間");
        this.setFlag({ key: "time_pause", status: true });
        return;
      }
      this.toggleGamePause(true);

      // this.gameLogCreate({
      //   label: (this.gamePaused) ? 'game_pause' : 'game_start',
      //   payload: {
      //     changed_at: new Date().getTime()
      //   }
      // })
    },
    getFlag(flagName) {
      return this.flag(flagName);
    },
    _setFlag(flagName, value) {
      this.setFlag({ key: flagName, status: value });
    },
    customService() {
      // window.open("https://line.me/R/oaMessage/@489zbckq?message");
      window.open("https://lin.ee/WYQgbHE");

    }
  },
  mounted: function() {
    if (!this.user) {
      let user = {
        id: 1,
        email: "deathhell",
        nickname: "死神",
        avatar: "",
        score: 60
      };
      this.default_user = user;
    } else {
      this.default_user = this.user;
    }

    let rankPos = this.team_percent || 0;
    rankPos = (599 * (100 - rankPos)) / 100 - 26;
    this.rankPosition = rankPos;
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";
.character-text {
  font-size: 1.4rem;
}

.main-menu-tabs li {
  flex: 1;
  cursor: hand;
}
.half-circle {
  top: calc(100vh - 100px);
  right: 50%;
  z-index: 10000;
  transform: translate(50%, -20%);
  transition: 0.5s;
  &.active {
    top: 20px;
    .menu-arrow {
      transform: rotate(180deg);
    }
  }
}
.menu-circle {
  width: 50px;
  height: 25px;
  border-radius: 50px 50px 0 0;
  background: white;
}

.character-tab {
  background-repeat: no-repeat;
  background-size: 100vw auto;
  background-position: 30vw 30vh;
  .character-skill {
    top: -20px;
  }
}

.character-intro {
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  padding: 20px 20px 40px;
  color: #224363;
}
.icon {
  width: 24px;
  height: 24px;
}
strong {
  color: #4285f4;
}
</style>
