<template>
  <li>
    <div class="d-flex align-items-center position-relative">
      <img v-if="rank == 0" class="position-absolute team-crown" src="@/assets/images/icon-crown.svg" alt="" />
      <div class="cha-circle cha-circle-s circle mr-3 position-relative">
        <img class="cover-fit" v-bind:src="showAvatar(member.picture)" />
      </div>
      <div class="light-color team-cha-name mr-2">{{ name }}</div>
      <div class="team-cha-nickname text-truncate">{{ member.nickname }}</div>
    </div>
    <div class="team-favorite-progress d-flex align-items-center position-relative">
      <div class="favorite-progress favorite-small">
        <div class="favorite-progress-bar" v-bind:style="{ width: score_percent + '%' }"></div>
      </div>
      <svg class="big-heart position-absolute">
        <use xlink:href="#icon-heart" class="heart-shadow" v-bind:class="[heart]"></use>
      </svg>
    </div>
  </li>
</template>

<script>
import Avatar from "@/Mixins/Avatar";

export default {
  name: "teamMemberScore",
  mixins: [Avatar],
  props: {
    member: {
      default: {}
    },
    rank: {
      default: 0
    },
    roleName: {
      default: ""
    }
  },
  computed: {
    score_percent() {
      let score = this.member.score / 100;
      score = score > 100 ? 100 : score;

      return score;
    },
    heart() {
      if (this.member.score >= 10000) {
        return "fill-heart";
      } else {
        return "fill-heart-not";
      }
    },
    name() {
      if (this.roleName.length) return this.roleName;
      else return this.member.name;
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.team-crown {
  width: 10px;
  left: 14px;
  top: -12px;
}
.team-cha-name {
  width: 80px;
}
</style>
